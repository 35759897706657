export const ColumnHSizes = {
    Address: { width: '16rem' },
    ApprovedDenied: { width: '8rem' },
    Barrier: { width: '12rem' },
    City: { width: '12rem' },
    Date: { width: '8rem' },
    Degree: { width: '8rem' },
    HCP: { width: '27rem' }, //Overridden by hardcoded logic?
    MFPV: { width: '6rem' },
    TargetType: { width: '7rem' },
    Territory: { width: '17rem' }, //Overridden by hardcoded logic?
    State: { width: '6rem' },
    YesNo: { width: '6rem' },
    Zip: { width: '6rem' },
}