/**
 * Is imported by qsmashup-object
 * Handles table data and imports custom-flex-table
 */
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import {
	CaretDownOutlined,
	CaretUpOutlined,
	CaretRightOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import CustomFlexTable from './custom-flex-table/custom-flex-table';
import BasicTable from './basic-table/basic-table';
import './qscustom-table.scss';
import SearchPopup from './search-popup/search-popup';
import Utils from '@trinity-incyte/utils';
import { useQSTable } from '@trinity-incyte/hooks';
import { MosaicGlobal, QlikHeader, QlikVisualizationInstance } from '@trinity-incyte/api-interfaces';
import { showExports } from 'libs/ui/src/mocks/custom-flex-table';

declare var Mosaic:MosaicGlobal;

const Styles = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const TableContainer = styled.div`
	display: flex;
	height: 100%;
	flex-flow: column;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
	margin-left: 15px;
`;

const customSortOrderIndicator = {
    '2021-Q1Pace to Goal': (val) => {
        let icon = <CaretUpOutlined />;
        if (val < 0.9) {
            icon = <CaretDownOutlined />;
        } else if (val < 0.97) {
            icon = <CaretRightOutlined />;
        }
        return icon;
    },
};

const getDefaultTableStyles = () => {
	//	default widths for specific columns should only be placed here
	const widths = {
		defaultWidth: '12rem',
		'0': '200px',
	};
	//	default styles applied to all header cells. Widths should not passed here
	const headerCellStyles = {};
	//	default styles applied to all body cells
	const bodyCellStyles = {};
	// eslint-disable-next-line
	// these styles applied to the header cells of those columns
	const specificColHeaderCellStyle = {
		// for column 0, these styles will be applied, widths accepted
		0: { width: '20' },
		1: { width: '20' },
		// eslint-disable-next-line
	};
	// these styles applied to the body cells of those columns. Do not pass widths here
	const specificColBodyCellStyle = {
		0: {},
		1: {},
	};
	return {
		headerCellStyles,
		bodyCellStyles,
		specificColHeaderCellStyle,
		specificColBodyCellStyle,
		widths,
	};
};

const profHeaders = ['PROF ID', 'PROF FULL NAME (ID)', 'PROF NAME (ID)', 'HCP', 'BLINDED PROF FULL NAME (ID)']; // Any columns.toUpperCase named this will automatically get ProfCard & Mosaic addons

/* eslint-disable-next-line */
export interface QSCustomTableProps {
	appId: string,
	qViz: QlikVisualizationInstance,
	tableProps?: any,
	isBasicTable?: boolean,
	customClass?: string,
	objectKey?: string,
	setCounter?: any,
	subPage?: any,
	skipProfHeaders?: boolean,
	dynamic?: boolean,
	groupHeads?: any,
	showTotals?: "top"|"bottom",
	hideSearch?: boolean,
  	noResize?: boolean,
	hideTitle?: boolean,
 	isSubtitleRed?: boolean,
	nonInteractive?: boolean,
	mashupId?: string
}

export function QSCustomTable(props: QSCustomTableProps) {
	let {
		appId,
		tableProps,
		customClass = 'qscustom-table',
		objectKey = '',
		qViz,
		setCounter,
		subPage,
		skipProfHeaders = false,
		dynamic = false,
		groupHeads,
        showTotals = false,
		hideTitle = false,
        isSubtitleRed = false,
		nonInteractive = false,
  } = props;

	const app = Mosaic.Qlik.app[appId];
	const [searchOpen, set_searchOpen] = useState(false);
	const [searchMeta, set_searchMeta] = useState({
		isDimension: null,
		isMeasure: null,
		parent: null,
		appId: null,
		element: document.body,
		id: null,
		fieldExpression: null,
	});
	const [sticky, setSticky] = useState(false);
	const [tableStyles, setTableStyles] = useState<any>(
		getDefaultTableStyles()
	);
	const loadTableStyleOverrides = ({
		bodyStyles,
		headerStyles,
		colOverrides,
	}) => {
		if (headerStyles && headerStyles.width) {
			tableStyles.widths.defaultWidth = Utils.getPXValue(
				headerStyles.width
			);
		} else {
			tableStyles.widths.defaultWidth = Utils.getPXValue(
				tableStyles.widths.defaultWidth
			);
		}
		Object.keys(tableStyles.widths).map((key) => {
			tableStyles.widths[key] = Utils.getPXValue(tableStyles.widths[key]);
		});
		//	these are the final styles that are going to be set on react-table
		let overrideStyles = {
			widths: { ...tableStyles.widths }, //	widths of specific columns
			headerCellStyles: {
				//	styles applied to all header cells. Widths accepted
				...tableStyles.headerCellStyles,
				...headerStyles,
			},
			bodyCellStyles: {
				//	styles applied to all body cells. Widths not acceped
				...tableStyles.bodyCellStyles,
				...bodyStyles,
			},
			//	styles applied to header cells of specific columns. Widths accepted
			specificColHeaderCellStyle: {
				...tableStyles.specificColHeaderCellStyle,
			},
			//	styles applied to body cells of specific columns. Widths not acceped
			specificColBodyCellStyle: {
				...tableStyles.specificColBodyCellStyle,
			},
			stylesOverriden: true,
		};

		if (colOverrides && colOverrides.length > 0) {
			for (let i = 0; i < colOverrides.length; i += 1) {
				for (
					let colIndex = 0;
					colIndex < colOverrides[i].columns.length;
					colIndex += 1
				) {
					if (colOverrides[i].hStyles.width) {
						overrideStyles.widths[
							colOverrides[i].columns[colIndex]
						] = Utils.getPXValue(colOverrides[i].hStyles.width);
						overrideStyles.specificColHeaderCellStyle[
							colOverrides[i].columns[colIndex]
						] = colOverrides[i].hStyles;
					} else {
						overrideStyles.specificColHeaderCellStyle[
							colOverrides[i].columns[colIndex]
						] = colOverrides[i].hStyles;
					}
					overrideStyles.specificColBodyCellStyle[
						colOverrides[i].columns[colIndex]
					] = colOverrides[i].bStyles;
				}
			}
		}
		delete overrideStyles.headerCellStyles.width;
		Object.keys(overrideStyles.specificColHeaderCellStyle).map((key) => {
			// delete overrideStyles.specificColHeaderCellStyle[key].width;
			// object is a readonly object so can't be modified directly. Need to create a new object to clear values
			const {
				width,
				...values
			} = overrideStyles.specificColHeaderCellStyle[key];
			overrideStyles.specificColHeaderCellStyle = {
				...overrideStyles.specificColHeaderCellStyle,
				[key]: {
					...values,
				},
			};
		});
		return overrideStyles;
	};

	const { object, rows, headers, getMore, triggerSort } = useQSTable({
		qViz,
		dynamic,
		options: {
			rowLimit: props?.tableProps?.rowLimit || null
		}
	});

	/**
	 * Use alphanumeric rules for sorting when a date is not provided.
	 */
	const sortByType = useCallback((rowA, rowB, id) => {
		const value1 = rowA.values[id],
			value2 = rowB.values[id],
			date1 = new Date(rowA.values[id]),
			date2 = new Date(rowB.values[id]),
			val1 = Number(value1?.replace(/[,%]/g, '')),
			val2 = Number(value2?.replace(/[,%]/g, ''));
		if (isNaN(val1) && isNaN(val2) && date1.valueOf() && date2.valueOf()) {
			return date1 > date2 ? 1 : -1;
		} else if (!value1 && isNaN(value1)) {
			return -1;
		} else if (!value2 && isNaN(value2)) {
			return 1;
		} else if (!isNaN(val1) && !isNaN(val2)) {
			return val1 > val2 ? 1 : -1;
		} else {
			return value1 > value2 ? 1 : -1;
		}
	}, []);

	const filterHeaderData = useCallback((headerRow: QlikHeader[]) => {
		let headerGrouper = new Array(headerRow.length);
		headerGrouper.fill(undefined);
		const tempCounts = {};
		const tempGroups = {};
		if (groupHeads) {
			// Generate the list of groups to grab by index
			groupHeads.reduce((acc, item) => {
				item.columns.forEach((col) => {
					acc[col] = item.title;
				});
				return acc;
			}, headerGrouper);
		}
		const headers = headerRow
			.map((headerData, index) => {				
//				let title = (headerData.qFallbackTitle || headerData.qText || `Column ${index + 1}`).trim().replace(/\s+/g, ' ');
				let title = (headerData.qFallbackTitle || `Column ${index + 1}`).trim().replace(/\s+/g, ' ');
				if (headerData.errorCode || title === 'Count' || title === 'Cnt') return;
				const hasField = !!headerData.field;
				if (!tempCounts[title]) {
					tempCounts[title] = 1;
				} else {
					tempCounts[title] += 1;
					title = `${title} (${tempCounts[title]})`;
				}
				const width = (() => {
					let val;
					if (
						!skipProfHeaders &&
						profHeaders.indexOf(title.toUpperCase()) >= 0
					) {
						/* NOTE! This will override any hstyle width set on these columns */
						val = Utils.getPXValue('28rem');
					} else if (title.substring(0,10)=="Territory ") {
						val = Utils.getPXValue('17rem');
					} else if (tableStyles.widths[index]) {
						val = tableStyles.widths[index];
					} else {
						val = tableStyles.widths.defaultWidth;
					}
					return val;
				})();
				
				let sortUsed = sortByType;
				if (tableProps && tableProps.colOverrides &&  tableProps.colOverrides.length>=index) {
					if (tableProps.colOverrides[index]?.customSort) {
						sortUsed = tableProps?.colOverrides[index]?.customSort;
					}
				} 

				const header: any = {
					accessor: title.split(' ').join('').split('.').join(''),
					Header: title,
					fallbackTitle: headerData.qFallbackTitle || title,
					filter: 'fuzzyText',
					dataParent: headerData.parent,
					width,
					hide: title.indexOf('Column') >= 0,
					isDimension: hasField,
					isMeasure: !hasField,
					fieldExpression: hasField
						? headerData.field.fldname
						: title,
					sortType: sortUsed,
					syntheticColumnId: headerData.cId,
				};

				const groupHeader = headerGrouper[index];
				if (groupHeader) {
					// Concatenates headers in one element of the list
					let toReturn = false;
					if (!tempGroups[groupHeader]) {
						toReturn = true;
						tempGroups[groupHeader] = [];
					}
					tempGroups[groupHeader].push(header);
					let groupHead = groupHeads.find(({ title }) => title === groupHeader);
					return toReturn
						? {
							Header: groupHeader,
							fallbackTitle: groupHeader, 
							customClass: groupHead?.customClass || 'group-header',
							columns: tempGroups[groupHeader],
						}
						: null;
				}

				return header;
			})
			.filter((item) => item); // Removes elements set to null
		return headers;
	}, [tableStyles]);

	const doClickAction = (el, event) => {
		if (el.classList.contains('table-container') || el === document.body) {
			return; // Don't bubble up from the table container or body
		} else {
			const {
				action,
				rowId: rowInd,
				colId: cellInd,
				elemNumber,
			} = el.dataset;
			if (!action) {
				// Bubble up
				return doClickAction(el.parentElement, event);
			} else if (action === 'open-link') {
				return;
			} else {
				event.stopPropagation();
				event.preventDefault();
			}

			if (action === 'qlik-select' && rowInd && cellInd) {
				qViz.model.selectHyperCubeValues(
					'/qHyperCubeDef',
					parseInt(cellInd, 10),
					[parseInt(elemNumber, 10)],
					true
				);
			}
			if (action === 'open-search') {
				const data = el.dataset;
				set_searchMeta({
					isDimension: data.isdimension === 'true',
					isMeasure: data.ismeasure === 'true',
					id: data.id,
					fieldExpression: data.fieldexpression,
					parent: qViz.table,
					appId: qViz.qapp.id,
					element: el,
				});
				set_searchOpen(true);
				return;
			} else {
				// It's not a selectable cell from QS or not selectable
				return;
			}
		}
	};

	const delegatedCellClick = (e) => {
		const { target } = e;
		if (!target) {
			return;
		} else if (
			target.dataset.action === 'none' ||
			target.dataset.action === 'self' ||
			nonInteractive
		) {
			e.stopPropagation();
			e.preventDefault();
			return;
		} else {
			doClickAction(target, e);
		}
	};

	const [tableHeaderData, setTableHeaderData] = useState(
		filterHeaderData(qViz.table.headers)
	);

	useEffect(() => {
		if (setCounter) {
			setCounter({
				rowCount: qViz.table.rowCount,
				colCount: qViz.table.colCount,
			});
		}
		setSticky(
			(object &&
				object.model &&
				object.model.layout &&
				object.model.layout.scrolling &&
				object.model.layout.scrolling.keepFirstColumnInView) ||
			false
		);

		setTableHeaderData(() => filterHeaderData(qViz.table.headers));
	}, [rows.length]);

	useEffect(() => {
		const overrideStyles = loadTableStyleOverrides(tableProps || {});
		// Locks all selection made before the table is loaded
		// so that table selections will be cleared
		if (subPage) {
			app.lockAll();
		}
		setTableStyles(overrideStyles);
		return () => {
			if (subPage) {
				app.clearAll().then(() => app.unlockAll());
			}
			// qViz.close();
		};
	}, []);

	const showHeading =
		tableProps === undefined ||
		(tableProps &&
			(tableProps.heading === true || tableProps.heading === undefined));

	const filterRowsData = useCallback((rowsData) =>
		rowsData.map((rowData) => {
			const filteredRow = {
				qStates: [],
				qElemNumbers: [],
				qStyles: [],
				syntheticColumnIds: [],
			};
      const tempCounts = {};
      // Filter rows with an error code provided
      qViz.table.headers.filter(({errorCode}) => !errorCode)
        .forEach((headerData, headerIndex) => {
          let title = (headerData.qFallbackTitle || headerData.qText || `Column ${headerIndex + 1}`).replace(/\s+/g, ' ');
					const cell = rowData[headerIndex];
          if (!tempCounts[title]) {
            tempCounts[title] = 1;
          } else {
            tempCounts[title] += 1;
            title = `${title} (${tempCounts[title]})`;
          }
          filteredRow[
            title.split(' ').join('').split('.').join('')
          ] = cell ? cell.qText : '-';

          if (title === 'Count' || title === 'Cnt') return;
          filteredRow.qStates.push(cell?.qState); // Add qState for styling
          filteredRow.qElemNumbers.push(cell?.qElemNumber); // Add qElemNumbers for selection
          filteredRow.syntheticColumnIds.push(cell?.syntheticColumnId); // Add columnId for selection
          // Get color data
          const qStyle = {
            backgroundColor: null,
            color: null,
            padding: null,
            icon: null,
          };
          if (cell?.qAttrExps?.qValues?.length > 0) {
            const cellAttrs = cell.qAttrExps.qValues;
            for (let ii = 0; ii < cellAttrs.length; ii += 1) {
              const attrValue = cellAttrs[ii];
							let attrDefinition = null;
              if (headerData.qMeasureInfo) {
                attrDefinition = headerData.qMeasureInfo.qAttrExprInfo[ii];
              } else if (headerData.qDimensionInfo) {
                attrDefinition = headerData.qDimensionInfo.qAttrExprInfo[ii];
              }
              if (!attrDefinition) continue;

              if (attrDefinition?.id?.toUpperCase() === 'CELLBACKGROUNDCOLOR') {
                qStyle.backgroundColor = attrValue.qText;
                qStyle.color = 'aliceblue';
								if (props.tableProps?.customCellPadding) {
									qStyle.padding = props.tableProps.customCellPadding;
								} else {
									qStyle.padding = '2px 2px';
								}
              } else if (attrDefinition?.id?.toUpperCase() === 'CELLFOREGROUNDCOLOR') {
                qStyle.color = attrValue.qText;
                if (headerData?.qMeasureInfo?.representation?.type === 'indicator') {
                  const sorter =
                    customSortOrderIndicator[headerData.qMeasureInfo.qFallbackTitle];
                  if (sorter) {
                    qStyle.icon = sorter(cell.qNum);
                  } else {
                    qStyle.icon = (cell.qNum < 0)
                      ? <CaretDownOutlined />
                      : <CaretUpOutlined />;
                  }
                }
              }
            }
					}
				filteredRow.qStyles.push(qStyle);
			});
			return filteredRow;
		}), [qViz.table.headers]);

	const filteredData = filterRowsData(rows);

	// Create the totals row
	if (showTotals && rows.length > 0 && Array.isArray(object.table.totals) && object.table.totals.length > 0) {
        let measureIndex = -1;
        const totalsArray = object.table.headers
            .filter(({errorCode}) => !errorCode) // Filter rows with an error code provided
            .map((val, ind) => {
                let retVal;
                if (val.qDimensionInfo) {
                    retVal = {
                        qText: ind === 0 ? 'Totals' : '',
                        qElemNumber: -1,
                        qState: 'X',
                    };
                } else if (val.qMeasureInfo) {
                    measureIndex += 1;
                    retVal = object.table.totals[measureIndex];
                    retVal.qElemNumber = -1;
                    retVal.qState = 'X';
                }
                return retVal;
            });
        const totalRow = filterRowsData([totalsArray])[0];
        totalRow.isTotal = true;
        if (showTotals === 'top') {
            filteredData.unshift(totalRow);
        } else if (showTotals === 'bottom') {
            filteredData.push(totalRow);
        }
  }
  if (props.isBasicTable) {
		return (
			<BasicTable 
					alternateCellColors={props.tableProps?.alternateCells}
					columns={tableHeaderData}
					data={filteredData}
					tableStyles={tableStyles}
					getMore={getMore}
					customClass={customClass}
					objectKey={objectKey}
					triggerSort={(nonInteractive) ? ()=>{} : triggerSort}
					initialState={props.tableProps?.initialState}
					id={props.mashupId}
					{...props} // eslint-disable-line
			/>
		)
	} else {
		return (
			<Styles className={customClass} id={props.mashupId}>
				{showHeading && (
					<>
						{!hideTitle &&
							<div className="table-title"
								style={{ paddingBottom: qViz.model.layout.subtitle ? '' : '8px' }}
							>
								{qViz.model.layout.title}
								{!dynamic && qViz.table.rowCount > rows?.length && (props?.tableProps?.rowLimit === null) && <StyledLoadingOutlined/>}
							</div>
						}
						{hideTitle && showExports &&
						<div className="table-title"
						style={{ paddingBottom: qViz.model.layout.subtitle ? '' : '8px' }}
						>&nbsp;
						</div>}
						{qViz.model.layout.subtitle && (
							<div className={(isSubtitleRed ? 'table-subtitle red' : 'table-subtitle')}>
								{qViz.model.layout.subtitle}
							</div>
						)}
					</>
				)}
				<TableContainer
					className="table-container"
					onClick={delegatedCellClick}
					onKeyUp={delegatedCellClick}
				>
					<CustomFlexTable
						alternateCellColors={props.tableProps?.alternateCells}
						columns={tableHeaderData}
						data={filteredData}
						tableStyles={tableStyles}
						sticky={sticky}
						getMore={getMore}
						triggerSort={(nonInteractive) ? ()=>{} : triggerSort}
						initialState={props.tableProps?.initialState}
						id={props.mashupId}
						{...props} // eslint-disable-line
					/>
					{searchOpen && (
						<SearchPopup
							focus
							handleClose={() => set_searchOpen(false)}
							{...searchMeta}
						/>
					)}
				</TableContainer>
			</Styles>
		);
	}

}

export default QSCustomTable;