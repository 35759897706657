import { ColumnHSizes } from "./ColumnSizes"

export const JakafiPrimaryTargets = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_Primary Targets',
    colOverrides: [
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5], hStyles: ColumnHSizes.Degree },
        { columns: [6], hStyles: ColumnHSizes.TargetType },
        { columns: [7, 8, 9], hStyles: ColumnHSizes.Territory },
    ]
}

export const JakafiNewPatients = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_New Patients',
    colOverrides: [
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: { width: '11rem' } }, //Patient Id
        { columns: [2], hStyles: ColumnHSizes.Address },
        { columns: [3], hStyles: ColumnHSizes.City },
        { columns: [4], hStyles: ColumnHSizes.State },
        { columns: [5], hStyles: ColumnHSizes.Zip },
        { columns: [6], hStyles: ColumnHSizes.Date },
        { columns: [7, 8, 9, 10], hStyles: { width: '10rem' } },
        { columns: [11, 12, 13], hStyles: ColumnHSizes.Territory }
    ]
}

export const JakafiNewPrescribers = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_New Prescribers',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5], hStyles: ColumnHSizes.Date }, 
        { columns: [6], hStyles: { width: '6rem' } }, 
        { columns: [7], hStyles: ColumnHSizes.Date },  
        { columns: [8], hStyles: ColumnHSizes.TargetType },
        { columns: [9, 10, 11], hStyles: ColumnHSizes.Territory }  
    ]  
}

export const JakafiPrescribers = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_Prescribers',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5], hStyles: { width: '7rem' } }, 
        { columns: [6, 7], hStyles: ColumnHSizes.Date },
        { columns: [8, 9, 10, 11, 12, 13, 14], hStyles: { width: '6rem' } },  
        { columns: [15], hStyles: ColumnHSizes.TargetType },
        { columns: [16, 17, 18], hStyles: ColumnHSizes.Territory }  
    ]
}

export const JakafiBVEnrollments = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_BV Enrollments',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: { width: "8rem" } }, //NPI 
        { columns: [2], hStyles: { width: "16rem" } }, //Site
        { columns: [3], hStyles: ColumnHSizes.Address },
        { columns: [4], hStyles: ColumnHSizes.City },
        { columns: [5], hStyles: ColumnHSizes.State },
        { columns: [6], hStyles: ColumnHSizes.Zip },
        { columns: [7, 8], hStyles: { width: '8rem' } }, 
        { columns: [9, 10], hStyles: ColumnHSizes.Date },
        { columns: [11, 12, 13], hStyles: { width: "11rem" } },
        { columns: [14], hStyles: ColumnHSizes.MFPV },
        { columns: [15, 16], hStyles: ColumnHSizes.ApprovedDenied },
        { columns: [17, 18, 19], hStyles: { width: "11rem" } },
        { columns: [20, 21, 22], hStyles: ColumnHSizes.Territory}  
    ]
}

export const JakafiDirectSalesAccts = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_Direct Sales Accts',
    colOverrides: [  
        { columns: [0], hStyles: { width: "22rem" } },  //Account
        { columns: [1], hStyles: { width: "12rem" } },  //Record Type
        { columns: [2], hStyles: ColumnHSizes.Address },
        { columns: [3], hStyles: ColumnHSizes.City },
        { columns: [4], hStyles: ColumnHSizes.State },
        { columns: [5], hStyles: ColumnHSizes.Zip },
        { columns: [6, 7, 8, 9, 10, 11, 12], hStyles: { width: '6rem' } },  
        { columns: [13, 14, 15], hStyles: ColumnHSizes.Territory }  
    ]
}

export const JakafiNewAPPPrescribers = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_New APP Prescribers',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },  
        { columns: [5], hStyles: ColumnHSizes.Date },
        { columns: [6], hStyles: { width: '6rem' } },  
        { columns: [7], hStyles: ColumnHSizes.Date },
        { columns: [8], hStyles: ColumnHSizes.TargetType },
        { columns: [9], hStyles: ColumnHSizes.YesNo },
        { columns: [10], hStyles: { width: "11rem" } },
        { columns: [11], hStyles: { width: "6rem" } },
        { columns: [12], hStyles: { width: "11rem" } },  
        { columns: [13], hStyles: ColumnHSizes.Date },
        { columns: [14, 15, 16, 17], hStyles: { width: "6rem" } },  
        { columns: [18, 19, 20], hStyles: ColumnHSizes.Territory },  
    ]
}

export const JakafiAPPBarriers = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_APP Barriers',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: { width: "12rem" } }, //Specialty Desc
        { columns: [2], hStyles: ColumnHSizes.Address },
        { columns: [3], hStyles: ColumnHSizes.City },
        { columns: [4], hStyles: ColumnHSizes.State },
        { columns: [5], hStyles: ColumnHSizes.Zip },
        { columns: [6], hStyles: ColumnHSizes.Barrier },
        { columns: [7], hStyles: ColumnHSizes.Date },
        { columns: [8], hStyles: ColumnHSizes.Barrier },
        { columns: [9], hStyles: ColumnHSizes.Date },
        { columns: [10], hStyles: ColumnHSizes.Barrier },
        { columns: [11], hStyles: ColumnHSizes.Date },
        { columns: [12], hStyles: ColumnHSizes.Barrier },
        { columns: [13], hStyles: ColumnHSizes.Date }, 
        { columns: [14, 15, 16], hStyles: ColumnHSizes.Territory },  
    ]
}

export const JakafiAPPInitiatingHCP = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_APP Initiating HCP',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5], hStyles: ColumnHSizes.Degree },
        { columns: [6], hStyles: ColumnHSizes.Date },
        { columns: [7], hStyles: { width: "6rem" } }, //First Rx
        { columns: [8], hStyles: ColumnHSizes.TargetType },
        { columns: [9], hStyles: ColumnHSizes.YesNo },
        { columns: [10], hStyles: ColumnHSizes.MFPV }, //Diag
        { columns: [11], hStyles: { width: "8rem" } }, //Patient Flag
        { columns: [12], hStyles: { width: "8rem" } }, //Rx Sequence Num
        { columns: [13], hStyles: { width: "8rem" } }, //Patient Id
        { columns: [14], hStyles: ColumnHSizes.Date },
        { columns: [15, 16, 17, 18], hStyles: { width: "6rem" } },
        { columns: [19, 20, 21], hStyles: ColumnHSizes.Territory },  
    ]
}

export const JakafiClinicalAlertsByDate = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_Alert Listing',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5, 6, 7], hStyles: { width: "12rem" } },
        { columns: [8, 9], hStyles: ColumnHSizes.Date },
        { columns: [10], hStyles: ColumnHSizes.TargetType },
        { columns: [11, 12, 13], hStyles: ColumnHSizes.Territory }, 
    ]
}

export const JakafiMFPVDosingAlerts = {
    friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_Alert Listing OCNE Dismissal',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5, 6, 7], hStyles: { width: "12rem" } },
        { columns: [8], hStyles: ColumnHSizes.Date },
        { columns: [9], hStyles: { width: "12rem" } },
        { columns: [10], hStyles: ColumnHSizes.Date },
        { columns: [11], hStyles: ColumnHSizes.TargetType },
        { columns: [12], hStyles: ColumnHSizes.Date },
        { columns: [13, 14, 15], hStyles: ColumnHSizes.Territory }, 
    ]
}

export const MonjuviAPPTargets = {
    friendlyName: 'Mosaic_OCNE Reports - Monjuvi_table_Monjuvi Targets',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5], hStyles: ColumnHSizes.Degree },
        { columns: [6, 7], hStyles: ColumnHSizes.TargetType },
        { columns: [8, 9], hStyles: ColumnHSizes.Territory }, 
    ]
}

export const MonjuviAlertsByDate = {
    friendlyName: 'Mosaic_OCNE Reports - Monjuvi_table_Alert Listing',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5, 6, 7], hStyles: { width: "12rem" } },
        { columns: [8], hStyles: ColumnHSizes.Date },
        { columns: [9], hStyles: ColumnHSizes.TargetType },
        { columns: [10, 11], hStyles: ColumnHSizes.Territory }, 
    ]
}

export const PemazyreTargets = {
    friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Pemazyre Targets',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5], hStyles: ColumnHSizes.Degree },
        { columns: [6], hStyles: ColumnHSizes.TargetType },
        { columns: [7], hStyles: ColumnHSizes.Date },
        { columns: [8], hStyles: ColumnHSizes.TargetType },
        { columns: [9, 10, 11, 12, 13, 14, 15], hStyles: { width: "6rem" } },
        { columns: [16, 17], hStyles: ColumnHSizes.Territory },
    ]
}

export const PemazyreInteractions = {
    friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Pemazyre Interactions',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5], hStyles: ColumnHSizes.Date },
        { columns: [6, 7], hStyles: { width: "12rem" } },
        { columns: [8], hStyles: ColumnHSizes.YesNo },
        { columns: [9], hStyles: ColumnHSizes.TargetType },
        { columns: [10, 11], hStyles: ColumnHSizes.Territory },
    ]
}

export const PemazyreNewPatients = {
    friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Pemazyre New Patients',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip }, 
        { columns: [5], hStyles: ColumnHSizes.Date },
        { columns: [6, 7], hStyles: { width: '10rem' } }, 
        { columns: [8], hStyles: { width: '8rem' } }, 
        { columns: [9], hStyles: ColumnHSizes.TargetType },
        { columns: [10, 11], hStyles: ColumnHSizes.Territory },
    ]
}

export const PemazyreNewPrescribers = {
    friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Pemazyre New Prescribers',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5], hStyles: ColumnHSizes.Degree },
        { columns: [6], hStyles: ColumnHSizes.Date },
        { columns: [7], hStyles: { width: '6rem' } }, 
        { columns: [8], hStyles: ColumnHSizes.Date },
        { columns: [9], hStyles: ColumnHSizes.TargetType },
        { columns: [10, 11], hStyles: ColumnHSizes.Territory },
    ]
}

export const PemazyreAlertsByDate = {
    friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Alert Listing',
    colOverrides: [  
        { columns: [0], hStyles: ColumnHSizes.HCP },
        { columns: [1], hStyles: ColumnHSizes.Address },
        { columns: [2], hStyles: ColumnHSizes.City },
        { columns: [3], hStyles: ColumnHSizes.State },
        { columns: [4], hStyles: ColumnHSizes.Zip },
        { columns: [5, 6, 7], hStyles: { width: '12rem' } }, 
        { columns: [8, 9], hStyles: ColumnHSizes.Date },
        { columns: [10], hStyles: ColumnHSizes.TargetType },
        { columns: [11, 12], hStyles: ColumnHSizes.Territory },
    ]
}