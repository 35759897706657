import React from 'react';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { RGLGrid as Grid, LeftSlider } from '@trinity-incyte/ui';

const NiktimvoOverview = (props) => {
	const { profId } = props;
	const isProfSelected = use360ProfSelection(profId);

	let defaultLayout;
	if (profId === false) {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'green',
						text: 'Loading...',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId === '-') {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'orange',
						text: 'Please select one HCP to view the overview.',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId !== false && !isProfSelected) {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Loader',
					props: {
						color: 'blue',
						text: 'Loading...',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId !== '-' && profId !== false && isProfSelected) {
        defaultLayout = {
            responsive: [
                {
                    w: 15,
                    items: [
                        {
                            items: [
                                {
                                    w: 14,
                                    i: 'NIKTIMVO Multi Channel Engagement',
                                    props: { profId: profId },
                                },
                                {
									w: 10,
									items: [
										{
											w: 24,
											i: 'NIKTIMVO Diagnosis Claims Quintile',
											props: { profId: profId },											
										},
										{
											w: 24,
											i: 'NIKTIMVO Connections',
											props: { profId: profId },
										},
									]
                                },
                            ]
                        },
                        // {
                        //     style: {padding: '8px 0 0'},
                        //     items: [
                        //         {
                        //             w: 24,
                        //             i: 'NIKTIMVO Alerts',
                        //             props: { profId: profId },
                        //         }
                        //     ]
                        // },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 24,
                                    i: 'NIKTIMVO HCP Activity',
                                    props: { profId: profId },
                                }
                            ]
                        },
                    ]
                },
                {
                    w: 9,
                    items: [
                        {
                            items: [
                                {
                                    w: 24,
                                    i: 'NIKTIMVO Barriers Concept',
                                    props: { profId: profId },
                                },
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 24,
                                    i: 'NIKTIMVO GVHD Landscape',
                                    props: { profId: profId },
                                },
                            ]
                        }
                    ]
                }
            ]
        }
	} else {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'orange',
						text: 'Please select one HCP to view the overview.',
					},
					moved: false,
					static: true,
				},
			],
		};
	}

	return (
		<>
			<Grid
				title="Niktimvo Overview"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default NiktimvoOverview;
