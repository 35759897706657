/**
 * NOTE: the HEMONC App component and the Derm App component are almost identical
 * However, HEMONC uses the Library as the component in LibraryProvider, whereas Derm does not
 * Not combining the two components to maintain flexibility for the future
 */
import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { ConfigContext, LibraryContext, MosaicState } from '@trinity-incyte/context';
import { useAnalytics, useWalkme, useUserData, useAppMetadata } from '@trinity-incyte/hooks';
import {
	activePageState,
	AppState as AppStateAtom,
	openModalSelector,
	QSAppMetadataFamily,
	screenOrientationState,
	activeBrand as activeBrandAtom,
	activeTeam as activeTeamAtom,
	primaryTeam as primaryTeamAtom,
	defaultSelection as defaultSelectionAtom,
	QlikUser as QlikUserAtom,
	UserTerritory as UserTerritoryAtom,
	myGeoState,
} from '@trinity-incyte/recoil';
import {
	MaintenancePage,
	QSApp,
	QlikCannotConnect,
	QlikConnectionError,
	QlikHasDisconnected,
	QlikIsConnecting,
	QlikIsOpeningApp,
	Center
} from '@trinity-incyte/ui';
import { environment } from '../environments/environment';
import AppRouter from './Components/AppRouter';
import Config from './Config';

import '@trinity-incyte/assets/antd-overrides.less';
import '@trinity-incyte/assets/Brand.scss';
import '@trinity-incyte/assets/mosaic.scss';
import '@trinity-incyte/assets/Lato.scss';
import './app.scss';
import Library from './Components/Library';

declare const window: any;
window.Mosaic = { QlikAPI: null, Qlik: { app: [], temp: [] } };
window.MosaicTemp = {};

window.Mosaic.Qlik.criticalDataFetchQueue = window.Mosaic.Qlik.criticalDataFetchQueue || [];

function App() {
	const appIdFieldSales = Config.Qlik.FieldSales.ids.appId;
	const appIdFieldSalesNation = Config.Qlik.FieldSalesNation.ids.appId;
	const appIdProf360 = Config.Qlik.Prof360.ids.appId;
	const openModal = useSetRecoilState(openModalSelector);
	const changeScreenOrientation = useSetRecoilState(screenOrientationState);
	const activePage = useRecoilValue(activePageState);
	const [AppState, set_AppState] = useRecoilState(AppStateAtom);
	const [appFS, set_appFS] = useState<any>(null);
	const [appFSN, set_appFSN] = useState<any>(null);
	const [app360, set_app360] = useState<any>(null);

	const [ fieldSalesMetadata, setFieldSalesMetadata ] = useRecoilState(QSAppMetadataFamily(appIdFieldSales));
	const [ hcp360Metadata, setHcp360Metadata ] = useRecoilState(QSAppMetadataFamily(appIdProf360));
	const [ fieldSalesNationMetadata, setFieldSalesNationMetadata ] = useRecoilState(QSAppMetadataFamily(appIdFieldSalesNation));

	const isMetadataLoadComplete = fieldSalesMetadata && hcp360Metadata && appIdFieldSalesNation;

	useEffect(() => {
		const updateScreen = () => {
			changeScreenOrientation({ isPortrait: window.matchMedia( '(orientation: portrait)' ).matches })
		};

		window.addEventListener( 'resize', updateScreen, {passive: true} );

		return () => {
			window.removeEventListener( 'resize', updateScreen );
		};
	}, []);

	useEffect(() => {
		if (AppState.error) {
			console.error('AppState.error:', AppState.error);
		}
		if (( AppState.error === 'EXPIRED SESSION' ) || (AppState.error === 'NO QLIK')) {
			openModal({
				content: <QlikHasDisconnected />,
				size: 'large'
			});
		}
	}, [AppState.error]);

	useAnalytics({Config});
	useWalkme(environment.useWalkme, Config.App.walkmeUrl);

	const set_activeTeam = useSetRecoilState(activeTeamAtom);
	const set_primaryTeam = useSetRecoilState(primaryTeamAtom);
	const set_QlikUser = useSetRecoilState(QlikUserAtom);
	const set_UserTerritory = useSetRecoilState(UserTerritoryAtom);
	const set_activeBrand = useSetRecoilState(activeBrandAtom);
	const set_geographyFlag = useSetRecoilState(myGeoState);
	const set_defaultSelection = useSetRecoilState(defaultSelectionAtom);
	
	useMemo(() => {
		if (appFS != null) {
			const criticalDataFetchItems = [
				useUserData({Config, app: appFS, environment, set_activeTeam, set_primaryTeam, set_QlikUser, set_UserTerritory, set_activeBrand, set_geographyFlag, set_defaultSelection}),
				useAppMetadata({Config: Config, ids: Config.Qlik.FieldSales.ids, set_QSAppMetadata: setFieldSalesMetadata, environment}),
				useAppMetadata({Config: Config, ids: Config.Qlik.Prof360.ids, set_QSAppMetadata: setHcp360Metadata, environment}),
				useAppMetadata({Config: Config, ids: Config.Qlik.FieldSalesNation.ids, set_QSAppMetadata: setFieldSalesNationMetadata, environment})
			] 

			Promise.allSettled(criticalDataFetchItems).then((results) => {
				if (results.some(result => result.status === 'rejected')) {
					set_AppState({
					state: 'ERROR',
					error: 'NO PERMISSION',
					});
			
				} else {
					set_AppState({
					state: 'ALL GOOD',
					error: false,
					});
				}
			})
		}
	}, [appFS])

	let content;
	if ( Config.App.skipQlikAuth ) {
		content = <AppRouter />;
	} else if ( AppState.state === 'ERROR' && AppState.error === 'NO REQUIRE' ) {
		content = <QlikCannotConnect />;
	} else if ( AppState.state === 'ERROR' && AppState.error === 'NO PERMISSION' ) {
		content = <div style={{
			marginTop: '0px', 
			textAlign: 'center', 
			overflow: 'hidden', 
			height: '100%', 
			fontSize: '1.4rem', 
			backgroundColor: 'black',
			color: 'white'
		}}>
			<Center><p>You do not have access to this resource</p></Center>
		</div>;
	} else if ( AppState.state === 'MAINTENANCE' && AppState.error === 'MAINTENANCE' ) {
		content = <MaintenancePage />;
	} else if ( AppState.state ) {
		content = (
			<>
				{( AppState.state === 'LOADING' || AppState.state === 'LOADING QLIK' ) && (
					<QlikIsConnecting />
				)}
				{( AppState.state === 'LOADING APP' ) && (<QlikIsOpeningApp />)}
				{( AppState.state === 'LOADING APP' || AppState.state === 'ALL GOOD') && (
					<>
						<QSApp
							appId={appIdFieldSales}
							onAppLoad={(app) => {
								set_appFS(app);
							}}
						/>
						
						<QSApp
							appId={appIdFieldSalesNation}
							onAppLoad={(app) => {
								set_appFSN(app);
							}}
						/>

						<QSApp
							appId={appIdProf360}
							onAppLoad={(app) => {
								set_app360(app)
							}}
						/>
					</>
				)}
				{(( AppState.state === 'ALL GOOD' && activePage && isMetadataLoadComplete ) || (AppState.state === 'ERROR' && AppState.error === 'NO QLIK')) && (<AppRouter />)}
			</>
		);
	} else {
		content = <QlikConnectionError />;
	}

	return (
		<ConfigContext.Provider value={Config}>
			<LibraryContext.Provider value={Library}>
				<MosaicState>
					<Router basename={Config.App.routerBasename}>{content}</Router>
				</MosaicState>
			</LibraryContext.Provider>
		</ConfigContext.Provider>
	);
}

export default App;
