import { ConfigContext, HCP360State } from '@trinity-incyte/context';
import { usePageTitle, useQlikUserId } from '@trinity-incyte/hooks';
import {
	QSAppMetadataFamily,
	masqueradeUserId as masqueradeUserIdAtom,
	myGeoState,
	saveSearchResultsSelector,
} from '@trinity-incyte/recoil';

import { 
	Checkbox, 
	Col, 
	Divider, 
	Image,
	Input,
	List,
	Row, 
	Typography 
} from 'antd';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ReactResizeObserver from 'rc-resize-observer';
import React, { useContext, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { imgDoctorSearch } from '@trinity-incyte/assets';
import { Loader, Segment } from '@trinity-incyte/ui';
import HCP360Cell from '../../Addons/hcp360-cell/hcp360-cell';
import ProfCell from '../../Addons/prof-cell/prof-cell';
import { useBackTop } from '../back-top-indicator/back-top-indicator';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { MosaicGlobal, QlikAppInstance } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;
declare var window: any;

const StyledListItem = styled.div`
 padding: 0;
 font-weight: 700;
 font-size: 1.1rem;
 line-height: 1.3rem;
 color: #595959;
`;

// Create permutation of the array of values provided and
// returns array of strings of combined permutations
function permuter(value) {
    var permArr = [],
        usedChars = [];

    return (function permute(input) {
			var i, ch;
			for (i = 0; i < input.length; i++) {
				ch = input.splice(i, 1)[0];
				usedChars.push(ch);
				if (input.length == 0) {
					permArr.push(usedChars.slice());
				}
				permute(input);
				input.splice(i, 0, ch);
				usedChars.pop();
			}
			return permArr;
    })(value).map(comb => comb.join(' '))
}

function formatValue(value) {
	return value.replaceAll(',', '').split(' ');
}

function score(value, list) {
	let val = 0;
	list.map((item) => item.toUpperCase()).forEach((item) => {
		if (value.indexOf(item + ' ') !== -1) {
			val += 1;
		}
	});
	return val;
}

const searchDelay = 500;
const searchQlik = AwesomeDebouncePromise(
	(text, callback, { app, searchFields }: { app: QlikAppInstance, searchFields: any}) => {
		if (!callback) return null;
		if (app) {
			app.searchResults(
				text?.concat(permuter(text)),
				{ qOffset: 0, qCount: 15 },
				{
					qContext: 'CurrentSelections',
					qSearchFields: searchFields,
				},
				(reply) => {
					callback(reply, text);
				}
			);
		} else {
			callback(false, text);
		}
  },
  searchDelay
);


/* eslint-disable-next-line */
export interface MosaicSearchProps {
	app?: QlikAppInstance;
	showNPIFeature?: boolean;
	hideGeo?: boolean;
	showMyGeoControl?: boolean;
	searchFields: any;
	userField?: any;
	defaultTitle?: string;
	title?: string;
}

export function MosaicSearch({app, showNPIFeature, searchFields, userField, defaultTitle = 'Incyte Mosaic', title = 'HCP 360 - Search', showMyGeoControl = true}: MosaicSearchProps) {

	usePageTitle({
		defaultTitle,
		title
	})

	const [searchTerm, SETsearchTerm] = useState( '' );
	const [searchResults, SETsearchResults] = useState<any>( false );
	const [previewProfId, set_previewProfId] = useState( false );
	const [prevResults, setPrevResults] = useRecoilState<any>(saveSearchResultsSelector);
	const resetResults = useResetRecoilState(saveSearchResultsSelector);
	const [myGeoCheckboxState, set_myGeoCheckboxState] = useRecoilState(myGeoState);
	const userId = useQlikUserId();
	const [set_scroller, onResizer, backTop] = useBackTop();
	const Config = useContext(ConfigContext);
	const ProfKPIGroup = Config.App.profComponent;
	const isDerm = Config.App.name === "MosaicDerm";
	const masqueradeUserId = useRecoilValue(masqueradeUserIdAtom);
	const metadata = useRecoilValue(QSAppMetadataFamily(Config.Qlik.Prof360.ids.appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;

	const SearchResult = ( props ) => {
		let profNPI;
		let profName = props.qText.split(1)[0].replace(' (', '');
		let profID = props.qText.split('(')[1].split(')')[0];
		{showNPIFeature && (profNPI = props.qText.split('(')[2].split(')')[0])}
	
		return (
			<Row style={{ width: '100%' }}>
				<Col span={2}>
					<Image
						rootClassName={'sideNavImage'}
						style={{ verticalAlign: 'middle' }}
						preview={false}
						height={'1.5rem'}
						width={'1.5rem'}
						src={imgDoctorSearch}
						onClick={props.onPreview}
					/>
					</Col>
					<Col span={12}>
						<StyledListItem>{profName}</StyledListItem>
						<StyledListItem>Prof ID: {profID}</StyledListItem>
						{showNPIFeature && (<StyledListItem>NPI: {profNPI}</StyledListItem>)}
				</Col>
				<Col span={10}>
					<Row justify="end">
						<HCP360Cell fontSize={'1rem'} value={props.qText} />
						<ProfCell fontSize={'1rem'} value={props.qText} />
					</Row>
				</Col>
			</Row>
		)
	};

	useEffect(() => {

		resetResults();

		if (masqueradeUserId && myGeoCheckboxState) {
			// admins who check mygeo
			app.field(userField).selectValues([masqueradeUserId], false, false);
		} else if (masqueradeUserId && !myGeoCheckboxState) {
			// admins who uncheck mygeo
			app.field(userField).clear();
		} else if (showMyGeoControl  && myGeoCheckboxState && userId) {
			app.field(userField).selectValues([userId], false, false);
		} else if (showMyGeoControl  && !myGeoCheckboxState) {
			app.field(userField).clear();
		} else if (!masqueradeUserId) {
			app.field(userField).clear();
		}
		if (searchTerm) {
			changeHandler(searchTerm);
		}
		return () => {
			if (!masqueradeUserId) app.field(userField).clear();
		}
	}, [myGeoCheckboxState, userId, masqueradeUserId]);

	useEffect(() => {
		const selectionField = app.field(fields.get(Config.Qlik.Prof360.ids.fields['PROF ID SELECTION'])?.id);
		selectionField?.clear();
	}, [])

	const changeHandler = (elem) => {
		let formattedValue = formatValue(elem);
		window.MosaicTemp.formattedValue = formattedValue;
		SETsearchTerm(elem);
		SETsearchResults(false);
		if (prevResults.has(formattedValue.join(' ').trim())) {
			SETsearchResults(prevResults.get(formattedValue.join(' ').trim()));
			return;
		}

		searchQlik(
			formattedValue,
			(results, searchText) => {
				if (window.MosaicTemp.formattedValue === searchText) { //check for equality between searchtext and searchterm
					SETsearchResults(results);
				}
				if (results?.qResult?.qSearchGroupArray?.[0]?.qItems?.[0]?.qItemMatches?.length) {
					setPrevResults({ searchText: searchText.join(' '), results });
				}
			},
			{ app, searchFields }
		);
  };

	let searchResultsFragment;
	if ( searchTerm && searchResults ) {
		let formattedData = [];
		if ( searchResults.qResult && searchResults.qResult.qTotalNumberOfGroups === 1 ) {
			formattedData = searchResults.qResult.qSearchGroupArray[0].qItems[0].qItemMatches.sort(
				(valA, valB) => {
					// Sort function from MDN
					const nameA = valA.qText;
					const nameB = valB.qText;

					if (nameA < nameB) {
						return -1;
					} else if (nameA > nameB) {
						return 1;
					} else {
						return 0;
					}
				}
			);
		} else if ( searchResults.qResult && searchResults.qResult.qTotalNumberOfGroups >= 1 ) {
			const results = new Map();

			for ( let ii = 0; ii < searchResults.qResult.qSearchGroupArray.length; ii += 1 ) {
				const result = searchResults.qResult.qSearchGroupArray[ii];

				if ( results.has( result.qItems[0].qIdentifier )
					&& results.get( result.qItems[0].qIdentifier ).length >= result.qItems[0].qIdentifier ) {
					return; // QS passes double results, so need to de-dupe
				}

				const newResults = result.qItems[0].qItemMatches
					.sort(( valA, valB ) => {
						// Sort function from MDN
						const nameA = valA.qText;
						const nameB = valB.qText;

						if ( nameA < nameB ) {
							return -1;
						} else if ( nameA > nameB ) {
							return 1;
						} else {
							return 0;
						}
					})

				results.set( result.qItems[0].qIdentifier, newResults );
			}
            const checker = formatValue(searchTerm).filter(
                (item) => item
            );
            formattedData = Array.from(results, ([key, value]) => value)
                .reduce((curr, val) => {
                    return curr.concat(val);
                }, [])
                .sort(({ qText: name1 }, { qText: name2 }) => {
                    const score1 = score(name1, checker),
											score2 = score(name2, checker);
                    if (score1 > 1 && score1 > score2) {
											return -1;
                    } else if (score2 > 1 && score2 < score1) {
											return 1;
                    } else if (name1 > name2) {
											return 1;
                    } else if (score1 < score2 || name1 < name2) {
											return -1;
                    }
									return 0;
                });
        }

        searchResultsFragment = (
					<>
						<Virtuoso
							style={{
								width: '100%',
								height: '100%',
								marginTop: '1rem',
							}}
							scrollerRef={set_scroller}
							data={formattedData}
							overscan={20}
							components={{
								List: React.forwardRef(
									({ style, children }, listRef) => (
										<ReactResizeObserver onResize={onResizer}>
												<List
													style={{
															...style,
													}}
												>
													<div ref={listRef}>
														{children}
													</div>
												</List>
										</ReactResizeObserver>
										)
								),
								Item: ({ children, ...props }) => (
									<List.Item {...props} style={{ width: '100%', borderBottom: '1px solid #cad1da', padding: '0.4em 0' }}>
										{children}
									</List.Item>
								),
								EmptyPlaceholder: () => (
									<List
										style={{
											height: '100%',
											overflowY: 'auto',
											paddingTop: '1rem',
										}}
									>
										<List.Item className="no-padding">
											<Typography.Title level={5}>No Results</Typography.Title>
										</List.Item>
									</List>
								),
						}}
								itemContent={(_, val) => (
									<SearchResult
										{...val}
										onPreview={() => {
											set_previewProfId((prev) => {
												const value = val.qText
													.split('(')[1]
													.split(')')[0];

												return prev === value ? false : value;
											});
										}}
									/>
								)}
						/>
						{backTop}
				</>
		);
	} else if ( searchTerm !== '' ) {
		searchResultsFragment = (
			<List.Item>
				<div className="dimmer">
					<Loader text="Loading..." />
				</div>
			</List.Item>
		);
	} else {
		searchResultsFragment = ( <></> );
	}

	//
	let displayMyGeo = showMyGeoControl || masqueradeUserId;

	return (
		<Row style={{ height: '100%', width: '100%' }}>
			<Col span={9}>
				<div>
					<Segment style={{ margin: 0 }}>
						<Typography.Title level={5}>Search for:</Typography.Title>
						<List style={{ fontSize: '1.2rem' }}>
							<List.Item className="no-padding" style={{ border: 'none' }}> - HCP Name</List.Item>
							<List.Item className="no-padding" style={{ border: 'none' }}> - Prof ID</List.Item>
							{showNPIFeature && (<List.Item className="no-padding" style={{ border: 'none' }}> - NPI</List.Item>)}
						</List>
					</Segment>
					<Segment style={{ padding: '2rem', margin: 0 }}>
						{displayMyGeo && 
							<div>Search My Geography</div>
						}
						<Input
							onChange={({ target: { value } }) => changeHandler(value)}
							suffix={<SearchOutlined />}
							placeholder="Search..."
							style={{ width: '100%' }}
							value={searchTerm}
						/>
						{displayMyGeo && <Checkbox
							style={{
								fontSize: '1.28571429em',
								fontWeight: 'bold',
							}}
							onChange={(e) => {
								let newGeo = e.target.checked;
								set_myGeoCheckboxState(newGeo);
							}}
							checked={myGeoCheckboxState}
						>
							My Geography
						</Checkbox>}
					</Segment>
					<Segment style={{ margin: 0 }}>
						{( prevResults.size > 0 ) && (
							<List
								aria-label="Recent Searches"
							>
								<Typography.Title level={5}>Recent Searches</Typography.Title>
								{
									[...prevResults.keys()].map(( term, ind ) => (
										<List.Item
											onClick={() => changeHandler(term)}
											style={{
												display: 'flex',
												flexDirection: 'row',
												cursor: 'pointer',
												textDecoration: 'underline',
												padding: 0,
												fontSize: '1.4rem'
											}} 
											key={term}
										>
											{`${ind + 1}. ${term}`}
										</List.Item>
									))
								}
							</List>
						)}
					</Segment>
				</div>
			</Col>
			<Col span={1} style={{ maxWidth: '1%' }}>
				<Divider type='vertical' style={{ height: '100%', margin: 0 }}/>
			</Col>
			<Col span={7} style={{ height: '100%' }}>
				{searchResultsFragment}
			</Col>
			<Col span={1} style={{ maxWidth: '1%' }}>
				<Divider type='vertical' style={{ height: '100%', margin: 0 }}/>
			</Col>
			<Col span={6} style={{ height: '100%' }}>
				{( previewProfId ) && (( isDerm && <ProfKPIGroup vertical profId={previewProfId} />) || 
					(<HCP360State profId={previewProfId}><ProfKPIGroup vertical profId={previewProfId}/></HCP360State>))
				}
			</Col>
		</Row>
	);
};


export default MosaicSearch;